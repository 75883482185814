import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Button } from '/src/components/common'
import { NewsList } from '/src/components/news'

// @todo, join our community on click
// fires subscription modal.
const NewsPage = () => {
  return (
    <Layout hero={<HeroImage title="News & Updates" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={6}>
              <p>
                Keep up-to-date with news, promotions and other updates in the Metalcorp community. 
                Read below or join our community for content delivered straight to your inbox. 
              </p>
            </Col>
            <Col sm={6}>
              <div className="d-flex justify-content-end">
                <Button variant="primary">
                  Join Our Community
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 d-flex justify-content-center content-row">
        <Col xs={10}>
          <NewsList />
        </Col>
      </Row>
    </Layout>
  )
}

export default NewsPage

export const Head = () => {
  return (
    <>
      <title>News | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/og_news.jpg" />
    </>
  )
}
